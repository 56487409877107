import p1 from "../../../content/assets/imagesCarousel/img1.jpg"
import p0 from "../../../content/assets/imagesCarousel/img0.jpg"
import p2 from "../../../content/assets/imagesCarousel/img2.jpg"
import p3 from "../../../content/assets/imagesCarousel/img3.jpg"
import p4 from "../../../content/assets/imagesCarousel/img4.jpg"
import p5 from "../../../content/assets/imagesCarousel/img5.jpg"
import p6 from "../../../content/assets/imagesCarousel/img6.png"
// import p7 from "../../../content/assets/imagesCarousel/IMG_6079.png"
// import p8 from "../../../content/assets/imagesCarousel/IMG_6081.png"
// import p9 from "../../../content/assets/imagesCarousel/IMG_6089.png"
// import p10 from "../../../content/assets/imagesCarousel/IMG_6103.png"

const Images = [
  { photo: p1, in: "0" },
  { photo: p2, in: 1 },
  { photo: p3, in: 2 },
  { photo: p5, in: 4 },
  { photo: p6, in: 5 },
  { photo: p0, in: 6 },
  // { photo: p7, in: 6 },
  // { photo: p8, in: 7 },
  // { photo: p9, in: 8 },
  // { photo: p10, in: 9 },
]

export default Images
